<template>
  <b-sidebar
    id="karma-program-view-sidebar"
    ref="karma-program-view-sidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:sidebarActive', val)"
    no-close-on-backdrop
  >
    <template #default="{ hide }">
      <b-link class="text-primary floating-close" @click="hide">
        <feather-icon icon="XCircleIcon" size="26" class="mr-25" />
      </b-link>
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-2 border-bottom"
      >
        <h3 class="mb-0 font-weight-bold">View Karma Program</h3>
        <div>
          <!-- <b-button
            variant="outline-primary"
            class="mr-2 min-w-100 px-3"
            @click="hide"
          >
            Close
          </b-button> -->
        </div>
      </div>

      <!-- BODY -->
      <b-form autocomplete="off" id="karma-program-form" class="p-2">
        <b-row>
          <b-col md="12">
            <b-link
              v-if="$can('karma_program_show')"
              :to="{
                name: 'view-karma-program',
                params: { id: data_local.hashid },
              }"
              class="font-weight-bold d-inline-block text-nowrap"
              v-b-tooltip.hover.v-default
              title="View History"
            >
              View History
            </b-link>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label class="" for="karma-program-name">
                Karma Program name
              </label>
              <p class="text-capitalize">
                {{ data_local.name }}
              </p>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label class="" for="karma-program-admins">
                Karma Program Admins
              </label>
              <b-media
                v-if="data_local.users"
                v-for="user in data_local.users"
                :key="user.id"
              >
                <template #aside>
                  <b-avatar :src="user.avatar" />
                </template>
                <h6 class="mb-0">{{ user.name }}</h6>
                <p class="">{{ user.designation }}</p>
              </b-media>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label class="" for="karma-program-start-date">
                Start Date
              </label>
              <p class="text-capitalize">
                {{ data_local.start_date | formatDate }}
              </p>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label class="" for="karma-program-end-date"> End Date </label>
              <p class="text-capitalize">
                {{ data_local.end_date | formatDate }}
              </p>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label class="" for="status"> Status </label>
              <p class="text-capitalize">
                {{ data_local.status.replace(/_/g, " ") }}
              </p>
            </b-form-group>
          </b-col>

          <b-form-group
            v-if="customFields"
            v-for="(item, index) in customFields"
            :key="item.id"
            class="col-md-6"
          >
            <!-- #default="{ errors }" -->
            <validation-provider
              :name="item.name"
              rules=""
              #default="validationContext"
            >
              <b-form-group v-if="item.type == 'number'">
                <label class="" :for="item.name">{{ item.label }}</label>
                <b-form-input
                  :id="item.name"
                  :name="`custom_fields_data[${item.name}-${item.id}]`"
                  :value="data_local.get_customfields_data['field_' + item.id]"
                  :placeholder="item.label"
                  type="number"
                  :state="getValidationState(validationContext)"
                />
              </b-form-group>

              <b-form-group v-if="item.type == 'text'">
                <label class="" :for="item.name">{{ item.label }}</label>
                <b-form-input
                  :id="item.name"
                  :name="`custom_fields_data[${item.name}-${item.id}]`"
                  :value="data_local.get_customfields_data['field_' + item.id]"
                  :placeholder="item.label"
                  type="text"
                  :state="getValidationState(validationContext)"
                />
              </b-form-group>

              <b-form-group v-if="item.type == 'url'">
                <label class="" :for="item.name">{{ item.label }}</label>
                <b-form-input
                  :id="item.name"
                  :name="`custom_fields_data[${item.name}-${item.id}]`"
                  :value="data_local.get_customfields_data['field_' + item.id]"
                  :placeholder="item.label"
                  type="url"
                  :state="getValidationState(validationContext)"
                />
              </b-form-group>

              <b-form-group v-if="item.type == 'rating'">
                <label class="" :for="item.name">{{ item.label }}</label>
                <div class="d-flex align-items-center">
                  <star-rating
                    v-model="
                      data_local.get_customfields_data['field_' + item.id]
                    "
                    :show-rating="false"
                    :star-size="30"
                  ></star-rating>
                  <span
                    v-if="data_local.get_customfields_data['field_' + item.id]"
                    :class="`rating-btn btn ${
                      ratingdescription[
                        data_local.get_customfields_data['field_' + item.id]
                      ].class
                    }`"
                  >
                    {{
                      ratingdescription[
                        data_local.get_customfields_data["field_" + item.id]
                      ].text
                    }}</span
                  >
                  <input
                    type="hidden"
                    v-model="
                      data_local.get_customfields_data['field_' + item.id]
                    "
                    :name="`custom_fields_data[${item.name}-${item.id}]`"
                  />
                </div>
              </b-form-group>

              <b-form-group v-if="item.type == 'password'">
                <label class="" :for="item.name">{{ item.label }}</label>
                <b-form-input
                  :id="item.name"
                  :name="`custom_fields_data[${item.name}-${item.id}]`"
                  :value="data_local.get_customfields_data['field_' + item.id]"
                  :placeholder="item.label"
                  type="password"
                  :state="getValidationState(validationContext)"
                />
              </b-form-group>

              <b-form-group v-if="item.type == 'textarea'">
                <label class="" :for="item.name">{{ item.label }}</label>
                <b-form-textarea
                  :name="`custom_fields_data[${item.name}-${item.id}]`"
                  :value="data_local.get_customfields_data['field_' + item.id]"
                  trim
                  :placeholder="item.label"
                />
              </b-form-group>
              <b-form-group v-if="item.type == 'date'">
                <label class="" :for="item.name">{{ item.label }}</label>
                <flat-pickr
                  :config="{ dateFormat: 'Y-m-d' }"
                  :name="`custom_fields_data[${item.name}-${item.id}]`"
                  :value="data_local.get_customfields_data['field_' + item.id]"
                  class="form-control"
                  placeholder="Select Date.."
                />
              </b-form-group>

              <small class="text-danger">{{
                validationContext.errors[0]
              }}</small>
            </validation-provider>
          </b-form-group>
        </b-row>
      </b-form>
      <b-overlay :show="isBusy" no-wrap> </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  VBTooltip,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onMounted, ref, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import VueTimepicker from "vue2-timepicker/src";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";

// file-pond dependencies
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";

import useJwt from "@/auth/jwt/useJwt";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,

    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    VueTimepicker,
    FilePond,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  model: {
    prop: "sidebarActive",
    event: "update:sidebarActive",
  },
  props: {
    itemId: {
      type: String,
      required: false,
      default: "",
    },
    sidebarActive: {
      type: Boolean,
      required: true,
      default: true,
    },
    itemData: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isBusy: false,
      data_local: {
        status: "",
        get_customfields_data: [],
      },
      customFields: [],
      status_option: [],
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-karma-programs";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, karmaProgramStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  computed: {},
  watch: {
    sidebarActive(val) {
      this.data_local = {
        status: "",
        get_customfields_data: [],
      };
      if (this.itemId) {
        this.data_local = this.itemData;
      }
    },
  },
  created() {},
  mounted() {
    // this.getCustomFields();
  },
  methods: {
    openWindow(link) {
      window.open(link);
    },
    fetchItem(hashid) {
      this.$store
        .dispatch("app-karma-programs/fetchKarmaProgram", hashid)
        .then((res) => {
          this.data_local = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-karma-programs/getCustomFields")
        .then((res) => {
          self.customFields = res.data.data;
          self.status_option = res.data.status_select;
        })
        .catch((error) => {
          console.log(error);
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Custom Fields Getting Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Custom Fields Getting Failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
  },
};
</script>

<style lang="scss">
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.vue__time-picker {
  width: 6em !important;
}
.vue__time-picker input.display-time {
  width: 100% !important;
  background: whitesmoke;
  height: 38px;
  border-radius: 5px;
  margin-left: 3px;
  color: grey;
}
.vue__time-picker .dropdown,
.vue__time-picker-dropdown {
  height: 14rem !important;
}
.vue__time-picker .dropdown .select-list,
.vue__time-picker-dropdown .select-list {
  height: 14rem !important;
}
select#year,
select#month,
select#day {
  padding: 5px 10px;
  background: #eaeaea;
  border: solid rgb(218, 218, 218) 0.5px;
  border-radius: 5px;
  margin-left: 5px;
}
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.filepond--credits {
  display: none;
}
.flatpickr-input {
  background: whitesmoke !important;
}
</style>
