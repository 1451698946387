<template>
  <b-sidebar
    id="add-sidebar"
    ref="sidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:sidebarActive', val)"
    width="40%"
    no-close-on-backdrop
  >
    <template #default="{ hide }">
      <b-link class="text-primary floating-close" @click="hide">
        <feather-icon icon="XCircleIcon" size="26" class="mr-25" />
      </b-link>
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-2
          border-bottom
        "
      >
        <h3 v-if="data_local && !data_local.hashid" class="mb-0 font-weight-bold">
          New Karma Program
        </h3>
        <h3 v-else class="mb-0 font-weight-bold">Edit Karma Program</h3>
        <div>
          <b-button
            v-if="data_local && !data_local.hashid"
            variant="primary"
            @click="formSubmitted()"
            class="px-3"
          >
            Submit
          </b-button>

          <b-button v-else variant="primary" @click="formUpdate()" class="px-3">
            Update
          </b-button>
        </div>
      </div>

      <!-- BODY -->
      <validation-observer ref="accountRules" tag="form">
        <b-form autocomplete="off" id="karma-program-form" class="p-2">
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Karma Program name"
                label-for="karma-program-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Karma Program name"
                  rules="required"
                >
                  <b-form-input
                    id="karma-program-name"
                    v-model="data_local.name"
                    name="name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Karma Program name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Start Date" label-for="nhr-date">
                <flat-pickr
                  v-model="data_local.start_date"
                  :config="{
                    dateFormat: 'Y-m-d',
                    altFormat: 'd F Y',
                    altInput: 'true',
                  }"
                  class="form-control"
                  name="start_date"
                  placeholder="Select Start Date.."
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="End Date" label-for="nhr-date">
                <flat-pickr
                  v-model="data_local.end_date"
                  :config="{
                    dateFormat: 'Y-m-d',
                    altFormat: 'd F Y',
                    altInput: 'true',
                  }"
                  class="form-control"
                  name="end_date"
                  placeholder="Select End Date.."
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label class="required-l" for="status"> Status </label>
                <v-select
                  v-model="data_local.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="status_option"
                  :reduce="(val) => val.value"
                  label="label"
                  :clearable="false"
                  :searchable="false"
                  input-id="status"
                  name="status"
                  placeholder="Select Status"
                />
              </b-form-group>
            </b-col>

            <b-form-group
              v-if="customFields"
              v-for="(item, index) in customFields"
              :key="item.id"
              class="col-md-6"
            >
              <!-- #default="{ errors }" -->
              <validation-provider
                :name="item.name"
                rules=""
                #default="validationContext"
              >
                <b-form-group v-if="item.type == 'number'">
                  <label class="" :for="item.name">{{ item.label }}</label>
                  <b-form-input
                    :id="item.name"
                    :name="`custom_fields_data[${item.name}-${item.id}]`"
                    :value="
                      data_local.get_customfields_data['field_' + item.id]
                    "
                    :placeholder="item.label"
                    type="number"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>

                <b-form-group v-if="item.type == 'text'">
                  <label class="" :for="item.name">{{ item.label }}</label>
                  <b-form-input
                    :id="item.name"
                    :name="`custom_fields_data[${item.name}-${item.id}]`"
                    :value="
                      data_local.get_customfields_data['field_' + item.id]
                    "
                    :placeholder="item.label"
                    type="text"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>

                <b-form-group v-if="item.type == 'url'">
                  <label class="" :for="item.name">{{ item.label }}</label>
                  <b-form-input
                    :id="item.name"
                    :name="`custom_fields_data[${item.name}-${item.id}]`"
                    :value="
                      data_local.get_customfields_data['field_' + item.id]
                    "
                    :placeholder="item.label"
                    type="url"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>

                <b-form-group v-if="item.type == 'rating'">
                  <label class="" :for="item.name">{{ item.label }}</label>
                  <div class="d-flex align-items-center">
                    <star-rating
                      v-model="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :show-rating="false"
                      :star-size="30"
                    ></star-rating>
                    <span
                      v-if="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :class="`rating-btn btn ${
                        ratingdescription[
                          data_local.get_customfields_data['field_' + item.id]
                        ].class
                      }`"
                    >
                      {{
                        ratingdescription[
                          data_local.get_customfields_data["field_" + item.id]
                        ].text
                      }}</span
                    >
                    <input
                      type="hidden"
                      v-model="
                        data_local.get_customfields_data['field_' + item.id]
                      "
                      :name="`custom_fields_data[${item.name}-${item.id}]`"
                    />
                  </div>
                </b-form-group>

                <b-form-group v-if="item.type == 'password'">
                  <label class="" :for="item.name">{{ item.label }}</label>
                  <b-form-input
                    :id="item.name"
                    :name="`custom_fields_data[${item.name}-${item.id}]`"
                    :value="
                      data_local.get_customfields_data['field_' + item.id]
                    "
                    :placeholder="item.label"
                    type="password"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>

                <b-form-group v-if="item.type == 'textarea'">
                  <label class="" :for="item.name">{{ item.label }}</label>
                  <b-form-textarea
                    :name="`custom_fields_data[${item.name}-${item.id}]`"
                    :value="
                      data_local.get_customfields_data['field_' + item.id]
                    "
                    trim
                    :placeholder="item.label"
                  />
                </b-form-group>
                <b-form-group v-if="item.type == 'date'">
                  <label class="" :for="item.name">{{ item.label }}</label>
                  <flat-pickr
                    :config="{ dateFormat: 'Y-m-d' }"
                    :name="`custom_fields_data[${item.name}-${item.id}]`"
                    :value="
                      data_local.get_customfields_data['field_' + item.id]
                    "
                    class="form-control"
                    placeholder="Select Date.."
                  />
                </b-form-group>

                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-row>
        </b-form>
      </validation-observer>
      <b-overlay :show="isBusy" no-wrap> </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  VBTooltip,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onMounted, ref, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import VueTimepicker from "vue2-timepicker/src";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";

// file-pond dependencies
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";

import useJwt from "@/auth/jwt/useJwt";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
import moment from "moment";
import { bus } from "@/main.js";
// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,

    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    VueTimepicker,
    FilePond,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  model: {
    prop: "sidebarActive",
    event: "update:sidebarActive",
  },
  props: {
    itemId: {
      type: String,
      required: false,
      default: "",
    },
    sidebarActive: {
      type: Boolean,
      required: true,
      default: true,
    },
    itemData: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isBusy: false,
      data_local: {
        status: "",
        start_date: moment().format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
        get_customfields_data: [],
      },
      customFields: [],
      karmaProgramStatusOption: [],
      priority_option: [],
      status_option: [],
      myFiles: [],
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-karma-programs";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, karmaProgramStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  computed: {},
  watch: {
    sidebarActive(val) {
      this.data_local = {
        status: "",
        start_date: moment().format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
        get_customfields_data: [],
        files: [],
      };
      this.myFiles = [];
      if (this.itemId) {
        this.data_local = this.itemData;
      }
    },
  },
  created() {},
  mounted() {
    this.getCustomFields();
  },
  methods: {
    resetFormData() {
      console.log("reset");
      // this.data_local = {
      //   get_customfields_data: [],
      //   files: [],
      // };
      // if (this.itemId) {
      //   this.data_local = this.itemData;
      // }
    },
    openWindow(link) {
      window.open(link);
    },
    removeAttachment(uuid) {
      let data = {};
      data.url = "karma-programs/media/destroy/" + uuid;
      this.isBusy = true;
      this.$store
        .dispatch("app/delete", data)
        .then((res) => {
          this.isBusy = false;
          this.fetchItem(this.data_local.hashid);
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Attachment Deleted",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: "Attachment Deleted Successfully.",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Attachment Deleted',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Attachment Deleted Successfully',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Attachment Deletion failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Attachment Deletion failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    formSubmitted() {
      const self = this;
      const formData = new FormData(document.getElementById("karma-program-form"));

      formData.append("status", self.data_local.status);

      'karma-programs'

      this.isBusy = true;
      this.$store
        .dispatch("app-karma-programs/addKarmaProgram", formData)
        .then((res) => {
          // Add this karma-program to NHR
          this.isBusy = false;
          self.$refs.sidebar.hide();
          self.data_local = {
            status: "",
            start_date: moment().format("YYYY-MM-DD"),
            end_date: moment().format("YYYY-MM-DD"),
            get_customfields_data: [],
          };
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Karma Program Added",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Karma Program  Added',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          bus.$emit("loadKarmaPrograms");
        })
        .catch((error) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Karma Program Adding Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Karma Program Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    formUpdate() {
      const self = this;
      const formData = new FormData(document.getElementById("karma-program-form"));

      formData.append("status", self.data_local.status);
      formData.append("_method", "PATCH");

      let data = {};
      data.url = "karma-programs/" + self.data_local.hashid;
      self.data_local._method = "PATCH";
      data.params = formData;

      this.isBusy = true;
      this.$store
        .dispatch("app/update", data)
        .then((res) => {
          // Add this karma-program to NHR
          this.isBusy = false;
          self.$refs.sidebar.hide();
          self.data_local = {
            status: "",
            start_date: moment().format("YYYY-MM-DD"),
            end_date: moment().format("YYYY-MM-DD"),
            get_customfields_data: [],
          };
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Karma Program Updated",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Karma Program Updated',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });

        })
        .catch((error) => {
          this.isBusy = false;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Karma Program Updating Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Karma Program Updating Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    fetchItem(hashid) {
      this.$store
        .dispatch("app-karma-programs/fetchKarmaProgram", hashid)
        .then((res) => {
          this.data_local = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-karma-programs/getCustomFields")
        .then((res) => {
          self.customFields = res.data.data;
          self.karmaProgramStatusOption = res.data.meta.status;
          self.status_option = res.data.status_select;
        })
        .catch((error) => {
          console.log(error);
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Custom Fields Getting Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Custom Fields Getting Failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
  },
};
</script>

<style lang="scss">
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.vue__time-picker {
  width: 6em !important;
}
.vue__time-picker input.display-time {
  width: 100% !important;
  background: whitesmoke;
  height: 38px;
  border-radius: 5px;
  margin-left: 3px;
  color: grey;
}
.vue__time-picker .dropdown,
.vue__time-picker-dropdown {
  height: 14rem !important;
}
.vue__time-picker .dropdown .select-list,
.vue__time-picker-dropdown .select-list {
  height: 14rem !important;
}
select#year,
select#month,
select#day {
  padding: 5px 10px;
  background: #eaeaea;
  border: solid rgb(218, 218, 218) 0.5px;
  border-radius: 5px;
  margin-left: 5px;
}
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.filepond--credits {
  display: none;
}
.flatpickr-input {
  background: whitesmoke !important;
}
</style>
