<template>
  <div>
    <KarmaProgramAdd
      :item-id.sync="itemId"
      :item-data.sync="itemData"
      :sidebar-active.sync="sidebarActive"
      @refetch-data="refetchData"
    />
    <KarmaProgramViewSidebar
      :item-id.sync="itemId"
      :item-data.sync="itemData"
      :sidebar-active.sync="sidebarActive"
      @refetch-data="refetchData"
    />
    <!-- Filters -->
    <app-collapse hover>
      <app-collapse-item title="Filters">
        <KarmaProgramListFilters
          :status-filter.sync="statusFilter"
          :status-options="karmaProgramStatusOption"
        />
      </app-collapse-item>
    </app-collapse>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0 mt-2">
      <div class="m-1">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-checkbox class="mr-2" v-model="isDeleteMultipleCandChecked">
            </b-form-checkbox>
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              :searchable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div
              v-if="selectedKarmaPrograms.length == 0"
              class="d-flex align-items-center justify-content-end"
            >
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search Name"
              />
              <b-button
                v-if="$can('karma_program_create')"
                variant="primary"
                :to="{ name: 'add-karma-program' }"
              >
                <span class="text-nowrap d-none d-md-flex">New Karma Program</span>
		<span class="text-nowrap d-md-none">New</span>
              </b-button>
            </div>
            <b-button
              v-else-if="$can('karma_program_delete')"
              class="float-right"
              variant="danger"
              :disabled="karmaProgramsDeleteCount == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ karmaProgramsDeleteCount }} Karma Program</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-form-checkbox-group
        id="checkbox-group"
        v-model="selectedKarmaPrograms"
        name="selectedKarmaPrograms"
      >
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchKarmaProgramList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(select)="data">
            <div class="text-nowrap">
              <b-form-checkbox :value="data.item.hashid"> </b-form-checkbox>
            </div>
          </template>
          <!-- Column: User -->
          <template #cell(name)="data">
            <b-media vertical-align="center">
              <b-link
                v-if="$can('karma_program_show')"
                v-b-toggle.karma-program-view-sidebar
                @click="viewRecord(data)"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.name | str_limit(20) }}
              </b-link>
              <span v-else>
                {{ data.item.name | str_limit(20) }}
              </span>
            </b-media>
          </template>
          <template #cell(start_date)="data">
            {{ data.item.start_date | formatDate }}
          </template>
          <template #cell(end_date)="data">
            {{ data.item.end_date | formatDate }}
          </template>

          <!--Column: Status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
              class="text-capitalize"
            >
              {{ data.item.status.replace(/_/g, " ") }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <feather-icon
                v-if="$can('karma_program_edit')"
                @click="
                  $router.push({
                    name: 'edit-karma-program',
                    params: { id: data.item.hashid },
                  })
                "
                v-b-tooltip.hover
                title="Edit"
                icon="EditIcon"
                size="16"
                class="mr-1 cursor-pointer"
              />
              <feather-icon
                @click="viewRecord(data)"
                v-b-toggle.karma-program-view-sidebar
                v-if="$can('karma_program_show')"
                v-b-tooltip.hover
                title="View"
                icon="EyeIcon"
                size="16"
                class="cursor-pointer"
              />

              <b-dropdown
                variant="link"
                size="sm"
                toggle-class="text-decoration-none"
                no-caret
                v-if="$can('karma_program_delete')"
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle"
                  />
                </template>

                <b-dropdown-item
                  class="btn-sm p-0"
                  @click="confirmDeleteRecord(data)"
                  v-if="$can('karma_program_delete')"
                >
                  <feather-icon icon="TrashIcon" size="13" class="mr-50" />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
      </b-form-checkbox-group>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-if="selectedKarmaPrograms.length == 0"
              v-model="currentPage"
              :total-rows="totalKarmaProgramList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <b-button
              v-else-if="$can('karma_program_delete')"
              class="float-right"
              variant="danger"
              :disabled="karmaProgramsDeleteCount == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ karmaProgramsDeleteCount }} Karma Program</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { onUnmounted, watch, ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import karmaProgramStoreModule from "../karmaProgramStoreModule";
import useKarmaProgramList from "./useKarmaProgramList";
import KarmaProgramListFilters from "./KarmaProgramListFilters.vue";
import KarmaProgramAdd from "./KarmaProgramAdd.vue";
import KarmaProgramViewSidebar from "./KarmaProgramViewSidebar.vue";
import { bus } from "@/main.js";

export default {
  components: {
    KarmaProgramListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    AppCollapse,
    AppCollapseItem,
    vSelect,
    /* eslint-disable */
    ToastificationContent,
    /* eslint-enable */
    BFormCheckbox,
    BFormCheckboxGroup,
    KarmaProgramAdd,
    KarmaProgramViewSidebar,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  setup() {
    const REFERRAL_APP_STORE_MODULE_NAME = "app-karma-programs";
    const isDeleteMultipleCandChecked = ref(false);
    const selectedKarmaPrograms = ref([]);
    const karmaProgramsDeleteCount = ref(0);

    // Register module
    if (!store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        REFERRAL_APP_STORE_MODULE_NAME,
        karmaProgramStoreModule
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REFERRAL_APP_STORE_MODULE_NAME))
        store.unregisterModule(REFERRAL_APP_STORE_MODULE_NAME);
    });

    const statusOptions = [
      { label: "In Progress", code: 0 },
      { label: "Selected", code: 1 },
      { label: "Rejected", code: 2 },
    ];
    const {
      fetchKarmaProgramList,
      tableColumns,
      perPage,
      currentPage,
      totalKarmaProgramList,
      karmaProgramsMetaData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      statusFilter,
      filterByKarmaProgramUrgencyStatus,
      filterByKarmaProgramType,
      resolveUserStatusVariant,
      karmaProgramStatusOption,
    } = useKarmaProgramList();

    watch(isDeleteMultipleCandChecked, (val) => {
      selectedKarmaPrograms.value = [];
      if (val) {
        console.log(karmaProgramsMetaData.value);
        karmaProgramsMetaData.value.forEach((karma_program) => {
          selectedKarmaPrograms.value.push(karma_program.hashid);
        });
      }
    });

    watch(selectedKarmaPrograms, (val) => {
      karmaProgramsDeleteCount.value = val.length;
    });

    return {
      // Sidebar
      fetchKarmaProgramList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      totalKarmaProgramList,
      // Extra Filters
      statusOptions,
      statusFilter,
      filterByKarmaProgramUrgencyStatus,
      filterByKarmaProgramType,
      selectedKarmaPrograms,
      karmaProgramsDeleteCount,
      isDeleteMultipleCandChecked,
      resolveUserStatusVariant,
      karmaProgramStatusOption,
    };
  },
  data() {
    return {
      itemId: "",
      itemData: {},
      sidebarActive: false,
    };
  },
  created() {
    bus.$on("loadKarmaPrograms", () => {
      console.log("KarmaPrograms : loadKarmaPrograms");
      this.refetchData();
    });
  },
  methods: {
    addNew() {
      const self = this;
      self.itemId = "";
      self.itemData = {
        title: "",
      };
      self.sidebarActive = true;
      console.log("add new");
    },
    editRecord(data) {
      const self = this;
      self.itemData = data.item;
      self.itemId = data.item.hashid;
    },
    viewRecord(data) {
      const self = this;
      self.itemData = data.item;
      self.itemId = data.item.hashid;
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${data.item.name}.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.item.hashid);
        }
      });
    },
    confirmDeleteMultipleRecords() {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${this.selectedKarmaPrograms.length} Karma Program, This cannot be undone.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(
              "app-karma-programs/removeMultipleKarmaPrograms",
              this.selectedKarmaPrograms
            )
            .then((res) => {
              this.isDeleteMultipleCandChecked = false;
              this.selectedKarmaPrograms = [];
              if (res.status == "204") {
                this.refetchData();
                // this.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: "Deleted Successfully.",
                //     icon: "BellIcon",
                //     variant: "success",
                //     text: res.data.message,
                //   },
                // });
                this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Deleted Successfully',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
              } else {
                // this.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: "Karma Program Deletion failed.",
                //     icon: "BellIcon",
                //     variant: "danger",
                //     text: res.data.message,
                //   },
                // });
                this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Karma Program Deletion failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
              }
            })
            .catch((err) => console.log(err));
        }
      });
    },
    refetchKarmaProgramsData() {
      console.log("Added Karma Program");
    },
    deleteRecord(id) {
      const self = this;
      this.$store
        .dispatch("app-karma-programs/removeKarmaProgram", id)
        .then((res) => {
          if (res.status == "204") {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Karma Program Deleted",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: "Karma Program Deleted Successfully.",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Karma Program Deleted',
                icon: 'BellIcon',
                variant: 'success',
                text: "Karma Program Deleted Successfully.",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          } else {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Karma Program Deletion failed.",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Karma Program Deletion failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
          self.refetchData();
        })
        .catch((err) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Karma Program Deletion failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Karma Program Deletion failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
          console.error(err);
        });
    },
    openWindow(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
